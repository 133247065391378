import React from "react"

import Layout from "../layouts/layout"
import SEO from "../layouts/seo"

const TermsPage = () => {
  return (
    <Layout>
      <SEO title="Terms & conditions" />
      <article className="article legal">
        <h1 className="heading heading--md">
          Allgemeine Geschäftsbedingungen für den Service „Route-Optimizer“
        </h1>

        <p>
          der tiramizoo GmbH, vertreten durch den Geschäftsführer Martin Sträb,
          Wilhelm-Hale-Str. 50, 80639 München (nachfolgend „Unternehmen“)
        </p>

        <p>
          Das Unternehmen ermöglicht seine Kunden, Dritten (nachfolgend
          „Endkunden“) Zugang zu dem Dienst „Route Optimizer“ zu verschaffen,
          mit welchem diese Endkunden die Fahrtroute für die Lieferung von Waren
          mit Straßenfahrzeugen, insbesondere bei einer Mehrzahl von
          Haltepunkten (nachfolgend „Stopps“) im Hinblick auf die Länge der
          Route optimieren können, oder aber den Dienst „Route Optimizer selbst
          für eigene Lieferverpflichtungen zu verwenden. Das Unternehmen nutzt
          zur Ausführung des Dienstes „Route Optimizer“ eine selbst
          entwi-ckelte, proprietäre Software (nachfolgend „Software“), die es
          auf externen EDV-Anlagen ausführt und welche unter anderem über eine
          entsprechend vorbereitete Datenschnittstelle (nachfolgend „API“;
          gegebenenfalls unter Nutzung eines Internet-basierten Frontends,
          wel-ches über route-optimizer.tiramizoo.com zugänglich ist,
          nachfolgend „Plattform“) zugänglich gemacht werden kann
          Route-Optimizer(nachfolgend Software, externe EDV-Anlagen, API und
          Plattform Route-Optimizerauch zusammen das „Route-Optimizer-System“).
        </p>

        <h2>1. Vertragsgegenstand</h2>
        <p>
          Gegenstand dieses Vertrags ist die Bereitstellung des
          Route-Optimizer-Systems zur Nutzung seiner Funktionalitäten, die
          technische Ermöglichung der Nutzung des Route-Optimizer-Systems über
          die Plattform, die Einräumung bzw. Vermittlung von Nutzungsrechten an
          der Software sowie die Bereitstellung der von dem
          Route-Optimizer-System erzeugten Daten (nachfolgend
          „Anwendungsdaten“). Dabei erzeugt der Kunde mittels des
          Route-Optimizer-Systems Zugangsberechtigungscodes (nachfolgend
          „API-Keys“), die er an Endkunden übermittelt oder selbst nutzt, um
          mittels der API-Keys die Optimierung von Lieferrouten durch das
          Route-Optimizer-System vornehmen lassen zu können. Dabei übermittelt
          der Kunde bzw. Endkunde jeweils an das Route-Optimizer-System den
          eingesetzten Fahrzeugtyp und eine Reihe von Adressdaten, welche er als
          Abholort und Stopps definiert und welche von dem Fahrzeug zeitlich
          zusammenhängend abgefahren werden sollen (nachfolgend „Auftrag“), und
          das Route-Optimizer-System übermittelt an den Endkunden im Gegenzug
          die Anwen-dungsdaten, nämlich in welcher Reihenfolge und auf welchen
          Wegen Abholort und Stopps von einem Lieferfahrzeug abgefahren werden
          sollten (nachfolgend „Optimierung“), sofern das Gebiet, in welchem die
          Adressen belegen sind, von dem Unternehmen als „Liefergebiet“
          unterstützt wird. Jeder Auftrag kann eine unbegrenzte Anzahl von
          Stopps umfassen.
        </p>

        <h2>2. Bereitstellung des Route-Optimizer-Systems</h2>
        <p>
          2.1. Das Unternehmen hält ab dem Zeitpunkt des Vertragsbeginns (Ziffer
          2.3) auf einer oder mehreren externen EDV-Anlagen (nachfolgend auch
          bei Mehrzahl „Server“) das Route-Optimizer-System in der jeweils
          aktuellen Version zur Nutzung nach Maßgabe der nachfolgenden
          Regelungen bereit.
        </p>
        <p>
          2.2. Das Unternehmen trägt dafür Sorge, dass das bereit gestellte
          Route-Optimizer-System
        </p>
        <p>
          <ul>
            <li>
              für den sich aus dem Vertragsverhältnis ergebenden Zweck geeignet
              ist;
            </li>
            <li>während der gesamten Vertragslaufzeit frei von Mängeln ist,</li>
            <li>
              frei von Viren und ähnlichen Beschädigungen ist, welche die
              Tauglichkeit des Route-Optimizer-Systems zum vertragsgemäßen
              Gebrauch aufheben.
            </li>
          </ul>
        </p>
        <p>
          2.3. Um das Route-Optimizer-System nutzen zu können, hat dieser an der
          bezeichne-ten Stelle auf der Plattform zu registrieren, unter Angabe
          des Namens, der Firma, einer telefonnummer, einer E-Mail-Adresse und
          eines Passworts (nachfolgend „Kundendaten“). Daraufhin erhält der
          Kunde an die von ihm genannte E-Mail-Adresse einen Link (nachfolgend
          „Zugangslink“) gesandt, und wird durch ankli-cken des Zugangslinks in
          die Lage versetzt, das Route-Optimizer-System nutzen zu können
          (nachfolgend „Vertragsbeginn“).
        </p>
        <p>
          2.4. Das Unternehmen ist zur Durchführung von Updates, Patches und
          sonstigen Maßnahmen berechtigt, welche auf die Verbesserung des
          Route-Optimizer-Systems abzielen; ihm steht insofern ein einseitiges
          Leistungsbestimmungsrecht (§§ 315 ff. BGB) zu.
        </p>
        <p>
          2.5. Das Route-Optimizer-System und die Anwendungsdaten werden auf dem
          Server regelmäßig, mindestens kalendertäglich, gesichert. Für die
          Einhaltung handels- und steuerrechtlicher Aufbewahrungsfristen ist der
          Kunde verantwortlich.
        </p>
        <p>
          2.6. Übergabepunkt für das Route-Optimizer-System und die
          Anwendungsdaten ist der Routerausgang des Rechenzentrums, auf dessen
          Servern das Route-Optimizer-System bereitgestellt wird.
        </p>
        <p>
          2.7. Für die Beschaffenheit der erforderlichen Hard- und Software auf
          Seiten des Kun-den sowie für die Telekommunikationsverbindung zwischen
          dem Kunden und dem Unternehmen bis zum Übergabepunkt ist das
          Unternehmen nicht verantwortlich, wobei die Parteien darüber einig
          sind, dass dem marktüblichen Stand der Technik entsprechende Computer
          mit einer im digitalen Geschäftsverkehr gebräuchlichen
          Internetanbindung und jeweils aktuellem Betriebssystem für die Nutzung
          des Rou-te-Optimizer-Systems ausreichend sind.
        </p>

        <h2>
          3. Technische Verfügbarkeit des Route-Optimizer-Systems und des
          Zugriffs auf die Anwendungsdaten, Reaktions- und
          Wiederherstellungszeiten
        </h2>
        <p>
          Das Unternehmen schuldet eine Verfügbarkeit des
          Route-Optimizer-Systems und der Anwendungsdaten am Übergabepunkt zu 99
          % eines Kalenderjahres. Unter Verfügbarkeit verstehen die Parteien die
          technische Nutzbarkeit des Route-Optimizer-Systems und der
          Anwendungsdaten am Übergabepunkt zum Gebrauch durch den Kunden.
          Wartungs- und Reparaturzeiten in einem Umfang von zwei Stunden eines
          Kalendertags werden bei der Ermittlung der Verfügbarkeit nicht
          berücksichtigt.
        </p>

        <h2>4. Nichterfüllung von Hauptleistungspflichten</h2>
        <p>
          4.1. Gerät das Unternehmen mit der betriebsfähigen Bereitstellung des
          Route-Optimizer-Systems in Verzug, so richtet sich die Haftung nach
          Ziffer 11. Der Kunde ist zum Rücktritt vom Vertrag berechtigt, wenn
          das Unternehmen eine vom Kunden gesetzte dreiwöchige Nachfrist nicht
          einhält, mithin innerhalb der Nachfrist nicht die volle vereinbarte
          Funktionalität des Route-Optimizer-Systems zur Verfügung stellt.
          Geringfügige Funktionsbeeinträchtigungen bleiben außer Betracht.
        </p>
        <p>
          4.2. Ist eine Nutzung des Route-Optimizer-Systems nicht innerhalb von
          sechs (6) Werktagen, nachdem das Unternehmen vom Mangel Kenntnis
          erlangt hat, wieder möglich, so kann der Kunde unabhängig von dem
          Grund der Nichterfüllung, jedoch nicht, wenn ausschließlich höhere
          Gewalt vorliegt, das Vertragsverhältnis ohne Einhaltung einer Frist
          außerordentlich kündigen, wenn er zuvor eine angemessene Nachfrist zur
          Beseitigung des Nutzungshindernisses gesetzt hat, welche mindes-tens
          sechs (6) weitere Werktage beträgt.
        </p>
        <p>
          4.3. Das Unternehmen hat darzulegen, dass es den Grund für die
          verspätete Bereit-stellung oder den Leistungsausfall nicht zu
          vertreten hat. Hat der Kunde den Leis-tungsausfall dem Unternehmen
          nicht angezeigt, so hat der Kunde im Bestreitensfall zu beweisen, dass
          das Unternehmen anderweitig Kenntnis davon erlangt hat.
        </p>

        <h2>
          5. Nutzungsrechte an und Nutzung des Route-Optimizer-Systems, Rechte
          des Un-ternehmens bei Überschreitung der Nutzungsbefugnisse,
          Datenbanken
        </h2>
        <p>5.1. Nutzungsrechte an dem Route-Optimizer-System</p>
        <p>
          5.1.1. Der Kunde erhält an dem Route-Optimizer-System einfache (nicht
          unterlizen-zierbare und nicht übertragbare), auf die Laufzeit dieses
          Vertrages be-schränkte Nutzungsrechte nach Maßgabe der nachstehenden
          Regelungen.
        </p>
        <p>
          5.1.2. Eine Überlassung des Route-Optimizer-Systems an den Kunden
          erfolgt nicht. Der Kunde darf das Route-Optimizer-System nur für
          eigene geschäftliche Tä-tigkeiten nutzen.
        </p>
        <p>
          5.1.3. Der Kunde ist nicht berechtigt, Änderungen an dem
          Route-Optimizer-System vorzunehmen.
        </p>
        <p>
          5.1.4. Sofern das Unternehmen während der Laufzeit neue Versionen,
          Updates, Up-grades oder andere Neulieferungen im Hinblick auf das
          Route-Optimizer-System vornimmt, finden die Bestimmungen dieses
          Vertrags auch für diese uneingeschränkte Anwendung.
        </p>
        <p>
          5.1.5. Rechte, die vorstehend nicht ausdrücklich dem Kunden eingeräumt
          werden, stehen dem Kunden im Zweifel nicht zu. Dieser ist insbesondere
          nicht berech-tigt, das Route-Optimizer-System über die vereinbarte
          Nutzung hinaus zu nut-zen. Insbesondere ist es nicht gestattet, das
          Route-Optimizer-System zu ver-vielfältigen, zu veräußern oder zeitlich
          begrenzt zu überlassen, insbesondere nicht zu vermieten oder zu
          verleihen. Vervielfältigungsvorgänge technischer Art, welche für eine
          vertragsgemäße Nutzung erforderlich sind, bleiben außer Betracht.
        </p>

        <p>5.2. Verpflichtung des Kunden zur sicheren Nutzung</p>
        <p>
          Der Kunde trifft die notwendigen Vorkehrungen, um die Nutzung des
          Route-Optimizer-Systems durch Unbefugte zu verhindern.
        </p>
        <p>
          5.2.2. Der Kunde haftet jeweils dafür, dass das tiramizoo-System nicht
          zu rassistischen, diskriminierenden, pornographischen, den
          Jugendschutz gefährdenden, politisch extremen oder sonst
          gesetzeswidrigen oder gegen behördliche Vorschriften oder Auflagen
          verstoßenden Zwecken verwendet oder entsprechende Daten, insbesondere
          Anwendungsdaten, erstellt und/oder auf dem tiramizoo-System
          gespeichert werden.
        </p>

        <p>5.3. Verletzung der Bestimmungen nach Ziffern 5.1 und 5.2 </p>
        <p>
          5.3.1. Verletzt der Kunde schuldhaft die Regelungen in Ziffern 5.1
          oder 5.2, kann das Unternehmen den Zugriff des Kunden auf das
          Route-Optimizer-System oder die Anwendungsdaten sperren, wenn die
          Verletzung hierdurch nachweislich abgestellt werden kann.
        </p>
        <p>
          5.3.2. Verletzt der Kunde trotz entsprechender schriftlicher Abmahnung
          des Unter-nehmens weiterhin oder wiederholt schuldhaft die Regelungen
          in Ziffern 5.1 oder 5.2, so kann das Unternehmen den Vertrag ohne
          Einhaltung einer Kündi-gungsfrist außerordentlich kündigen.
        </p>

        <h2>6. Entgelt und Zahlung</h2>
        <p>
          Die Vergütung für die durch das Unternehmen während der
          Vertragslaufzeit erbrachten ver-traglichen Leistungen ergibt sich aus
          der jeweils aktuellen Preisliste des Unternehmens, wel-che unter
          https://route-optimizer.tiramizoo.com/pricing einsehbar ist. Die
          Vergütung ist unbar innerhalb von zehn (10) Werktagen nach
          Rechnungszugang an die in de Rechnung angege-bene Bankverbindung zu
          zahlen. Die Übermittlung der Rechnung erfolgt an die E-Mail-Adresse,
          welche Bestandteil der Kundendaten ist.
        </p>

        <h2>7. Pflichten und Obliegenheit des Kunden </h2>
        <p>
          Der Kunde wird alle Pflichten und Obliegenheiten erfüllen, die zur
          Abwicklung des Vertrags erforderlich sind. Er wird insbesondere
        </p>
        <p>
          7.1. die ihm zugeordneten Nutzungs- und Zugangsberechtigungen sowie
          gegebenen-falls vereinbarte Identifikations- und
          Authentifikations-Sicherungen – mit Ausnahme von API-Keys - geheim
          halten, vor dem Zugriff durch Dritte schützen und nicht an
          unberechtigte Personen weitergeben. Diese Daten sind durch geeignete
          und dem Stand der Technik entsprechende Maßnahmen zu schützen. Der
          Kunde wird das Unternehmen unverzüglich unterrichten, wenn der
          Verdacht besteht, dass der Zu-gangslink nicht berechtigten Personen
          bekannt geworden sein könnte;
        </p>
        <p>
          7.2. die Beschränkungen und Verpflichtungen im Hinblick auf die
          Nutzungsrechte nach Ziffer 5 einhalten;
        </p>
        <p>
          7.3. keine Informationen oder Daten unbefugt abrufen oder abrufen
          lassen oder in Pro-gramme, die von dem Unternehmen betrieben werden
          eingreifen oder eingreifen lassen oder in Datennetze des Unternehmens
          unbefugt eindringen oder ein sol-ches Eindringen fördern;
        </p>
        <p>
          7.4. den im Rahmen der Vertragsbeziehung und/oder unter Nutzung des
          Route-Optimizer-Systems gegebenenfalls möglichen Austausch von
          elektronischen Nachrichten nicht missbräuchlich für den
          unaufgeforderten Versand von Nachrich-ten und Informationen an Dritte
          zu Werbezwecken nutzen;
        </p>
        <p>
          7.5. das Unternehmen von Ansprüchen Dritter freistellen, die auf einer
          rechtswidrigen Verwendung des Route-Optimizer-Systems durch den Kunden
          beruhen oder die sich aus von dem Kunden verursachten
          datenschutzrechtlichen, urheberrechtli-chen oder sonstigen rechtlichen
          Streitigkeiten ergeben, die mit der Nutzung des
          Route-Optimizer-Systems verbunden sind;
        </p>
        <p>
          7.6. die berechtigten Nutzer des Route-Optimizer-Systems auf Seiten
          des Kunden (ins-besondere Mitarbeiter) verpflichten, ihrerseits für
          Einhaltung dieser Allgemeinen Geschäftsbedingungen Sorge zu tragen;
        </p>
        <p>
          7.7. die erforderliche Einwilligung von Betroffenen einholen, soweit
          bei Nutzung des Route-Optimizer-Systems personenbezogene Daten
          erhoben, verarbeitet oder ge-nutzt werden und kein gesetzlicher
          Erlaubnistatbestand eingreift;
        </p>
        <p>
          7.8. vor der Versendung von Daten und Informationen an das Unternehmen
          diese auf Viren prüfen und dem Stand der Technik entsprechende
          Virenschutzprogramme einsetzen;
        </p>
        <p>
          7.9. Mängel an Vertragsleistungen, insb. Mängel an den Leistungen nach
          Ziffern 2 und 3, dem Unternehmen unverzüglich anzeigen. Soweit das
          Unternehmen infolge der Unterlassung oder Verspätung der Anzeige nicht
          Abhilfe schaffen konnte, ist der Kunde nicht berechtigt, den Ersatz
          des durch den Mangel eingetretenen Schadens zu verlangen oder den
          Vertrag wegen des Mangels ohne Einhaltung einer Frist au-ßerordentlich
          zu kündigen;
        </p>
        <p>
          7.10. wenn er zur Erzeugung von Anwendungsdaten mit Hilfe des
          Route-Optimizer-Systems dem Unternehmen Daten übermittelt, diese
          regelmäßig und der Bedeu-tung der Daten entsprechend sichern und
          eigene Sicherungskopien erstellen, um bei Verlust der Daten und
          Informationen die Rekonstruktion derselben zu ermögli-chen;
        </p>
        <p>
          7.11. sofern und soweit ihm einvernehmlich die technische Möglichkeit
          dazu eröffnet wird, regelmäßig die auf dem Server gespeicherten
          Anwendungsdaten durch Download zu sichern; unberührt bleibt die
          Verpflichtung des Unternehmens zur Da-tensicherung nach Ziffer 2.5.
        </p>

        <h2>8. Datensicherheit, Datenschutz</h2>
        <p>
          8.1. Die Parteien werden die jeweils anwendbaren, insbesondere die in
          Deutschland gültigen datenschutzrechtlichen Bestimmungen beachten und
          ihre im Zusammen-hang mit dem Vertrag und dessen Durchführung
          eingesetzten Beschäftigten auf das Datengeheimnis verpflichten, soweit
          diese nicht bereits allgemein entspre-chend verpflichtet sind.
        </p>
        <p>
          8.2. Erhebt, verarbeitet oder nutzt der Kunde personenbezogene Daten,
          so steht er da-für ein, dass er dazu nach den anwendbaren,
          insbesondere datenschutzrechtli-chen Bestimmungen berechtigt ist, und
          stellt im Falle eines Verstoßes das Unter-nehmen von Ansprüchen
          Dritter frei.
        </p>
        <p>
          8.3. Das Unternehmen wird kundenbezogene Daten nur in dem Umfang
          erheben und nutzen, wie es die Durchführung dieses Vertrages
          erfordert. Der Kunde stimmt der Erhebung und Nutzung solcher Daten in
          diesem Umfang zu.
        </p>
        <p>
          8.4. Die Verpflichtungen nach Ziffern 8.1 bis 8.3 bestehen, so lange
          Anwendungsdaten im Einflussbereich des Unternehmens liegen, auch über
          das Vertragsende hinaus fort.
        </p>
        <p>
          8.5. Sofern der Kunde das Route-Optimizer-System zur Erfüllung eigener
          Lieferver-pflichtungen nutzt, schließen gemäß Art. 28 Abs. 3 DSGVO
          eine Vereinbarung über die Auftragsverarbeitung. Im Fall von
          Widersprüchen zwischen diesem Vertrag und der Vereinbarung über die
          Auftragsverarbeitung geht Letztere Ersterem vor.
        </p>

        <h2>9. Geheimhaltung</h2>
        <p>
          9.1. Die Vertragspartner werden über alle vertraulich zu behandelnden
          Informationen, die ihnen im Rahmen dieses Vertragsverhältnisses zur
          Kenntnis gelangt sind, Still-schweigen bewahren bzw. diese nur im
          vorher schriftlich hergestellten Einverneh-men der jeweils anderen
          Partei Dritten gegenüber – gleich zu welchem Zweck – verwenden. Zu den
          als vertraulich zu behandelnden Informationen zählen die von der
          informationsgebenden Partei ausdrücklich als vertraulich bezeichneten
          Infor-mationen und solche Informationen, deren Vertraulichkeit sich
          aus den Umständen der Überlassung eindeutig ergibt. Durch das
          Unternehmen vertraulich zu behan-deln sind insbesondere die
          Anwendungsdaten, sollte es von diesen Kenntnis erlan-gen.
        </p>
        <p>
          9.2. Die Verpflichtungen nach Ziffer 9.1 entfallen für solche
          Informationen oder Teile da-von, für die die empfangende Partei
          nachweist, dass sie
        </p>
        <p>
          <ul>
            <li>
              ihr vor dem Empfangsdatum bekannt oder allgemein zugänglich waren;
            </li>
            <li>
              der Öffentlichkeit vor dem Empfangsdatum bekannt oder allgemein
              zugänglich waren;
            </li>
            <li>
              der Öffentlichkeit nach dem Empfangsdatum bekannt oder allgemein
              zugänglich wurden, ohne dass die informationsempfangende Partei
              hierfür verantwortlich ist.
            </li>
          </ul>
        </p>
        <p>
          9.3. Öffentliche Erklärungen der Parteien über eine Zusammenarbeit
          werden nur im vorherigen gegenseitigem Einvernehmen abgegeben.
        </p>
        <p>
          9.4. Die Verpflichtungen nach Ziffer 9.1 bestehen auch über das
          Vertragsende hinaus auf unbestimmte Zeit, und zwar so lange, wie ein
          Ausnahmetatbestand nach Ziffer 9.2 nicht nachgewiesen ist, längstens
          jedoch fünf (5) Jahre.
        </p>
        <p>
          9.5. Die Parteien werden nur solchen Beratern Zugang zu vertraulichen
          Informationen gewähren, die dem Berufsgeheimnis unterliegen oder denen
          zuvor den Geheimhal-tungsverpflichtungen dieses Vertrags entsprechende
          Verpflichtungen auferlegt worden sind. Des Weiteren werden die
          Parteien nur denjenigen Mitarbeitern die vertraulichen Informationen
          offenlegen, die diese für die Durchführung dieses Ver-trags kennen
          müssen, und diese Mitarbeiter auch für die Zeit nach ihrem
          Aus-scheiden in arbeitsrechtlich zulässigem Umfang zur Geheimhaltung
          verpflichten.
        </p>

        <h2>
          10. Sanktion bei Verletzung der Verpflichtungen nach Ziffern 8 und 9
        </h2>
        <p>
          Verletzt der Kunde schuldhaft eine wesentliche Pflicht nach den
          Ziffern 8 und 9 oder aus der Vereinbarung zur Auftragsverarbeitung
          (Ziffer 8.5), so wird für jeden Fall der Verlet-zung eine angemessene,
          von dem Unternehmen nach billigem Ermessen gemäß § 315 BGB zu
          bestimmende und im Streitfall von dem zuständigen Landgericht zu
          überprüfende Vertragsstrafe verwirkt. Im Falle eines Dauerverstoßes
          ist die Vertragsstrafe für jeden angebrochenen Kalendermonat der Dauer
          des Verstoßes zu bemessen. Weiter kann je-de geschädigte Partei
          Schadensersatz nach Maßgabe von Ziffer 11 dieses Vertrags gel-tend
          machen, wobei die Anrechnung einer Vertragsstrafe nicht stattfindet.
        </p>

        <h2>11. Haftung, Haftungsgrenzen und Vertragsstrafe</h2>
        <p>
          11.1. Die Parteien haften einander bei Vorsatz oder grober
          Fahrlässigkeit für alle von ihnen sowie ihren gesetzlichen Vertretern
          oder Erfüllungsgehilfen verursachten Schäden unbeschränkt.
        </p>
        <p>
          11.2. Bei leichter Fahrlässigkeit haften die Parteien nur im Fall der
          Verletzung des Lebens, des Körpers oder der Gesundheit unbeschränkt.
        </p>
        <p>
          11.3. Im Übrigen haftet eine Partei nur, soweit sie eine wesentliche
          Vertragspflicht (Kardi-nalpflicht) verletzt hat. In diesen Fällen ist
          die Haftung auf den Ersatz des vorher-sehbaren, typischerweise
          eintretenden Schadens beschränkt. Die verschuldensun-abhängige Haftung
          des Unternehmens auf Schadensersatz (§ 536a BGB) für bei
          Vertragsschluss vorhandene Mängel wird ausgeschlossen; Ziffern 11.1
          und 11.2 bleiben unberührt.
        </p>
        <p>
          11.4. Eine Partei ist zur Zahlung einer Vertragsstrafe nur
          verpflichtet, wenn dies dieser Vertrag ausdrücklich vorsieht. Eine
          Vertragsstrafe braucht nicht vorbehalten zu werden. Die Aufrechnung
          mit ihr und gegen sie ist zulässig, soweit nicht ausdrück-lich
          ausgeschlossen.
        </p>
        <p>
          11.5. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.
        </p>

        <h2>12. Laufzeit, Kündigung</h2>
        <p>
          Das Vertragsverhältnis beginnt mit der erstmaligen Übermittlung von
          Anwendungsdaten an das Route-Optimizer-System durch den Kunden nach
          Vertragsbeginn. Eine Kündigung ist jeweils zum Ende des laufenden
          Monats, in welchem die Kündigungserklärung dem Unternehmen zugeht,
          möglich. Die Kündigungserklärung bedarf mindestens der Textform (§
          126b BGB). Das Recht zur außerordentlichen Kündigung bleibt unberührt.
        </p>

        <h2>13. Daten des Kunden bei Beendigung des Vertrags</h2>
        <p>
          Mit Beendigung des Vertragsverhältnisses ist das Unternehmen
          verpflichtet, die von dem Kunden übermittelten Kundendaten und
          Anwendungsdaten, soweit noch vorhanden, in-nerhalb eines Zeitraums von
          höchstens 90 Kalendertagen zu löschen. Es obliegt dem Kunden, die
          Anwendungsdaten vor der Löschung zu sichern.
        </p>

        <h2>14. Höhere Gewalt</h2>
        <p>
          Keine der Parteien ist zur Erfüllung der vertraglichen Verpflichtungen
          im Fall und für die Dauer höherer Gewalt verpflichtet. Insbesondere
          folgende Umstände sind als höhere Gewalt in diesem Sinne anzusehen:
        </p>

        <ul>
          <li>
            von der Partei nicht zu vertretende(s)
            Feuer/Explosion/Überschwemmung;
          </li>
          <li>Krieg, Meuterei, Blockade, Embargo;</li>
          <li>
            über sechs Wochen andauernder und von der Partei nicht schuldhaft
            herbeigeführter Arbeitskampf;
          </li>
          <li>
            nicht von einer Partei beeinflussbare technische Probleme des
            Internets.
          </li>
        </ul>

        <p>
          Jede Partei hat die andere über den Eintritt eines Falls höherer
          Gewalt unverzüglich schriftlich in Kenntnis zu setzen.
        </p>

        <h2>15. Schlussbestimmungen</h2>
        <p>
          15.1. Auf das Vertragsverhältnis findet deutsches Recht mit Ausnahme
          des Internationa-len Privatrechts Anwendung.
        </p>
        <p>
          15.2. Anlagen sind in ihrer jeweils aktuellen Fassung Bestandteil
          dieses Vertrags.
        </p>
        <p>
          15.3. Nebenabreden außerhalb dieses Vertrags und seiner Anlagen
          bestehen nicht. Än-derungen oder Ergänzungen dieses Vertrages und der
          Anhänge bedürfen der zu ih-rer Wirksamkeit der Schriftform, sofern
          nicht ausdrücklich eine andere Form in die-sem Vertrag vereinbart ist.
          Die Schriftform gilt im Übrigen auch für die Abbedingung des
          Schriftformerfordernisses.
        </p>
        <p>
          15.4. Die etwaige Unwirksamkeit einzelner Bestimmungen dieses Vertrags
          beeinträchtigt nicht die Gültigkeit des übrigen Vertragsinhalts.
        </p>
        <p>
          15.5. Ergeben sich in der praktischen Anwendung dieses Vertrags
          Lücken, die die Ver-tragspartner nicht vorgesehen haben, oder wird die
          Unwirksamkeit einer Regelung im Sinne von Ziffer 15.4 rechtskräftig
          oder von beiden Parteien übereinstimmend festgestellt, so verpflichten
          sie sich, diese Lücke oder unwirksame Regelung in sachlicher, am
          wirtschaftlichen Zweck des Vertrages orientierter angemessener Weise
          auszufüllen bzw. zu ersetzen.
        </p>
        <p>
          15.6. Ausschließlicher Gerichtsstand ist, sofern nicht das Gesetz
          zwingend einen ande-ren Gerichtsstand anordnet, der Sitz des
          Unternehmens.
        </p>
      </article>
    </Layout>
  )
}

export default TermsPage
